
import * as Actions from "./actions"
import { v4 as uuid } from "uuid";
// import { KEEPALIVE_TIMEOUT_SECONDS } from "../../src/ibdata"
import * as mylocalStorage from "../mylocalStorage";
import { setLangauge } from "../Translator/Translations";

export const KEEPALIVE_TIMEOUT_SECONDS = 60;
const initialState = {
    user: null,
    userProfile: undefined,
    tutorGroup: null,
    tutorUsertype: null,
    tutorTourDone: false,
    communityUser: null,
    authDialog: {
        open: false
    },
    countryCode: null,
    clientLoc: null,
    BoardConfig: {},
    participants: {},
    activeUsers: {},
    noUser: false,
    gvPage: 1,
    sessionDialog: {
        open: false
    },
    boardType: {
        name: "",
        single: false,
    },
    tab: {
        selected: 0,
        allTabs: [],
    },
    assistanceRequest: {
        studentAsked: false,
        pendingList: [],
    },
    boardChat: {
        started: false,
        messages: [],
        language: null
    },
    timer: {
        running: false,
        events: [],
    },
    boardLocked: {
        locked: false,
        events: [],
    },
    allEvents: {
        events: [],
    },
    pageLocked: {
        events: [],
        locked: false,
        myev: null,
    },
    showCase: {
        locked: false,
        dialog: false,
        events: [],
    },
    palletDrawer: {
        open: false,
        openCol: false,
        ref: null,
    },
    richText: {
        open: false,
        object: null,
        cb: null,
        loc: null,
    },
    pendingParticipants: [],
    avatarDialogOpen: false,
    diceShow: {
        teacher: {
            open: false,
            values: [],
            obj: null
        },
        board: {
            open: false,
            values: [],
            obj: null,
        }
    },
    followTeacher: {
        set: false,
        events: [],
    },
    clockDrift: 0,
    teacher: 0,
    hideNotification: false,
    syncDisabled: false,
    drawerWalker: {
        open: false,
        page: 1
    },
    gradingWalker: {
        open: false,
        page: 1
    },
    clickMode: {
        mode: "none",
        ctx: "none"
    },
    spinnerEvt: null,
    fDiceEvt: null,
    letItSnowEvt: null,
    personalConfig: {
        font: "Roboto",
        inkColor: true,
        dblClickDisable: false,
        language: mylocalStorage.getItem("language")
    },
    gridBrowser: {
        open: false,
        pageNum: 1,
        realTime: true,
        groupGridOnly: false,
        search: "",
        showLabels: [],
        hideLabels: []
    },
    matrixGrid: {
        classroom: null,
        gridMap: {}
    },
    timeMachine: {
        open: false,
        realTime: false,
        selectedTime: null,
        slider: 50,
    },
    background: {
        color: null,
        image: null,
        pickerOpen: false,
        pickerImageOpen: false,
        notmine: false,
    },
    boardTools: {},
    formSubmit: {
        events: [],
    },
    engagementScore: {},
    engagementSticker: null,
    meetingToJoin: null,
    apiMode: null,
    adsEnable: false,
    keybMode: { enable: false, lastKey: "" },
    videoState: { mode: "none" },
    modeSelected: null,
    mobileModeSelected: { open: false },
    videoConfig: null,
    pendingSubscriptions: {},
    alertMessages: {
        open: false,
        messages: []
    },
    sharedScreen: null,
    boardRecording: null,
    classPoll: {
        open: false,
        messages: {}
    },
    embeddedMode: null,
    pageAdding: false,
    sharedScreenDialog: {
        open: false
    }
}

var gParts = {};
var timedOutParticipants = {};

function getIdToUse(data) {
    var idUse = data.id
    if (data.UserProfile) {
        idUse = data.UserProfile
    } else {
        if (data.content) {
            const jp = JSON.parse(data.content)
            if (jp.localID) {
                idUse = jp.localID
            }
        }
    }
    return idUse
}

function setLocal(user) {
    const authid = user ? user.username : null;
    var luid = mylocalStorage.getItem('mystoreID');
    if (!luid) {
        luid = uuid()
        mylocalStorage.setItem('mystoreID', luid)
    }
    if (authid && luid !== authid) {
        mylocalStorage.setItem('mystoreID', authid)
        mylocalStorage.setItem('backupLocalID', luid);
        luid = authid //from now on the authenicated user and the stored user will be same
    }
    return luid
}

function setLocalPaidPlan(userProfile) {
    if (userProfile) {
        if (!userProfile.paidPlan) {
            mylocalStorage.removeItem('paidPlan');
        } else {
            mylocalStorage.setItem('paidPlan', userProfile.paidPlan);
        }
        if (!userProfile.TenantID) {
            mylocalStorage.removeItem('tenant');
        } else {
            mylocalStorage.setItem('tenant', userProfile.TenantID);
        }
    } else {
        mylocalStorage.removeItem('paidPlan');

    }
}

function studentRaiseHand(set) {

}
function pendingRequests(old, req) {
    var arr = [...old.pendingList]
    for (var i = 0; i < arr.length; i++) {
        var c = arr[i]
        if (c.id === req.id) {
            arr.splice(i, 1)
        }
    }
    if (req.State !== "Done") arr.push(req)
    var ff = {
        studentAsked: Boolean(arr.length > 0),
        pendingList: arr
    }
    return (ff)
}
function pendingChat(old, req, state) {
    var arr = [...old.messages]
    if (!req) return { started: false, messages: [] }
    var luid = mylocalStorage.getItem('mystoreID');
    if (state.teacher === 0 && req && req.Content && req.Content.filter) {
        //check for filter
        if (!req.Content.filter[luid]) {
            var ff = {
                started: Boolean(arr.length > 0),
                messages: arr
            }
            return (ff)
        }
    }
    for (var i = 0; i < arr.length; i++) {
        var c = arr[i]
        if (c.id === req.id) {
            arr.splice(i, 1)
        }
    }
    if (req.State !== "Done" && !req.deleted) arr.push(req)
    // //sort by ttime 
    // arr.sort((a, b) => {
    //     var dt1 = new Date(a.updatedAt)
    //     var dt2 = new Date(b.updatedAt)
    //     if (dt1 < dt2) return 1
    //     return -1 
    // })
    // for (let i=0; i<arr.length; i++) {
    //     let c = arr[i]
    //     console.log("ITEM ", i, c.Content.msg)
    // }

    ff = {
        started: Boolean(arr.length > 0),
        messages: arr
    }
    return (ff)
}

function pendingFollow(state, req) {
    if (!req) {
        return {
            set: false,
            events: [],
        }
    }
    var arr = [...state.followTeacher.events]
    for (var i = 0; i < arr.length; i++) {
        var c = arr[i]
        if (c.id === req.id) {
            arr.splice(i, 1)
        }
    }
    if (req.State !== "Done") arr.push(req)
    var ff = {
        set: Boolean(arr.length > 0),
        events: arr
    }
    return ({ ...ff })
}
function allEvents(state, req) {
    var arr = [...state.allEvents.events]
    for (var i = 0; i < arr.length; i++) {
        var c = arr[i]
        if (c.id === req.id) {
            arr.splice(i, 1)
        }
    }
    arr.push(req)
    var ff = { events: arr }
    return (ff)
}
function pendingLock(state, req) {
    if (!req) {
        return {
            locked: false,
            events: [],
        }
    }
    var arr = [...state.boardLocked.events]
    for (var i = 0; i < arr.length; i++) {
        var c = arr[i]
        if (c.id === req.id) {
            arr.splice(i, 1)
        }
    }
    if (req.State !== "Done") arr.push(req)
    var ff = {
        locked: Boolean(arr.length > 0),
        events: arr
    }
    return (ff)
}
function pendingPageLock(state, req) {
    if (!req) {
        return {
            events: [],
        }
    }
    var arr = [...state.pageLocked.events]
    for (var i = 0; i < arr.length; i++) {
        var c = arr[i]
        if (c.id === req.id) {
            arr.splice(i, 1)
        }
    }
    if (req.State !== "Done") arr.push(req)
    var ff = { events: arr }
    var rt = Actions.getPageLock(ff)
    ff['locked'] = rt.locked
    ff['myev'] = rt.ev
    return (ff)
}
function pendingShowCase(state, req) {
    if (!req) {
        return {
            locked: false,
            events: [],
        }
    }
    var arr = [...state.showCase.events]
    for (var i = 0; i < arr.length; i++) {
        var c = arr[i]
        if (c.id === req.id) {
            arr.splice(i, 1)
        }
    }
    if (req.State !== "Done") arr.push(req)

    var ff = {
        locked: Boolean(arr.length > 0),
        dialog: Boolean(arr.length > 1) || (Boolean(arr.length > 0) && state.BoardConfig.readOnlyShowCase),
        events: arr
    }
    return (ff)
}
function isObject(object) {
    return object != null && typeof object === 'object';
}

function compareParticipant(object1, object2, partId) {
    const keys1 = Object.keys(object1);
    const keys2 = Object.keys(object2);
    const curTime = new Date().getTime();
    let timedOut = 0;

    if (keys1.length !== keys2.length) {
        return false;
    }

    for (let key of keys1) {
        let obj1 = isObject(object1[key]);
        let obj2 = isObject(object2[key]);

        if (obj1 && obj2) {
            continue;
        } else if ((obj1 && !obj2) || (obj2 && !obj1)) {
            return false;
        }

        if (key === 'updatedAt') {
            let updTime = new Date(object2[key]);
            /**
            * Because the updates can happen at different times between different
            * participants, we need to check if an update was not received for
            * 2 timeout intervals to truly mark a participant as down or else
            * the state could keep fluctuating between timedOut = 0 and timedOut = 1
            */
            if (((curTime - updTime) / 1000) > (KEEPALIVE_TIMEOUT_SECONDS * 2)) {
                timedOut = 1;
            }

            if (!(timedOutParticipants[partId])) {
                timedOutParticipants[partId] = 0;
            }

            if (timedOutParticipants[partId] !== timedOut) {
                timedOutParticipants[partId] = timedOut;
                return false;
            }
        } else if (object1[key] !== object2[key]) {
            return false;
        }
    }

    return true;
}

function checkIfPartsUpdateNeeded(newParts) {
    const keys1 = Object.keys(gParts);
    const keys2 = Object.keys(newParts);

    if (keys1.length !== keys2.length) {
        return true;
    }

    for (let key of keys1) {
        if (newParts[key] === undefined) {
            return true;
        }

        if (!compareParticipant(gParts[key],
            newParts[key], key)) {
            return true;
        }
    }

    return false;
}

function removeMSTeamsEmail(em) {
    let email = em
    if (em && email.includes("@microsoftteams")) {
        email = email.split("@")[0]
        email = email.replace("__", "@")
    }
    return email
}
function pendingTimer(state, req) {
    if (!req) {
        return {
            running: false,
            events: [],
        }
    }
    var arr = [...state.timer.events]
    for (var i = 0; i < arr.length; i++) {
        var c = arr[i]
        if (c.id === req.id) {
            arr.splice(i, 1)
        }
    }
    if (req.State !== "Done") arr.push(req)
    var ff = {
        running: Boolean(arr.length > 0),
        events: arr
    }
    return (ff)
}
function processDice(state, e) {
    var open = true
    if (e.State === "Done") open = false
    var classDice = "board"
    if (e.teacherDice) {
        classDice = "teacher"
    }
    if (!e.Content.rollerName) e.Content.rollerName = ""
    var inval = e.Content.value
    var inval2 = Array.isArray(inval) ? inval : null
    var setVal = {
        ...state.diceShow,
        [classDice]: {
            open: open,
            obj: e,
            value: inval2,
            rollerName: e.Content.rollerName
        }
    }
    return (setVal)
}
function processSpinner(spinnerEvt, e, clockDrift) {
    const myTime = new Date()
    const evtTime = new Date(e.createdAt)
    // console.log("My time = ", myTime)
    // console.log("evtTime = ", evtTime)
    // console.log("Drift = ", clockDrift)
    // console.log("Difference = ", Math.abs(myTime - evtTime - clockDrift*1000))
    if (Math.abs(myTime - evtTime - clockDrift * 1000) > 5000) {
        // Too much difference in time, ignore event
        // console.log("Too much time difference in spin event, ignoring")
        return spinnerEvt;
    }
    return e;
}
function processFdice(fDiceEvt, e, clockDrift) {
    const myTime = new Date()
    const evtTime = new Date(e.createdAt)
    // console.log("My time = ", myTime)
    // console.log("evtTime = ", evtTime)
    // console.log("Drift = ", clockDrift)
    // console.log("Difference = ", Math.abs(myTime - evtTime - clockDrift*1000))
    if (Math.abs(myTime - evtTime - clockDrift * 1000) > 5000) {
        // Too much difference in time, ignore event
        // console.log("Too much time difference in spin event, ignoring")
        return fDiceEvt;
    }
    return e;
}
function processLetItSnow(letItSnowEvt, e, clockDrift) {
    const myTime = new Date()
    const evtTime = new Date(e.createdAt)
    if (Math.abs(myTime - evtTime - clockDrift * 1000) > 5000) {
        return letItSnowEvt;
    }
    return e;
}
function formSubmitprocess(state, req) {
    var arr = [...state.formSubmit.events]
    for (var i = 0; i < arr.length; i++) {
        var c = arr[i]
        if (c.id === req.id) {
            arr.splice(i, 1)
        }
    }
    arr.push(req)
    var ff = { events: arr }
    return (ff)
}
function processScore(state, dataIn) {
    if (!dataIn) {
        return {}
    }
    for (let i = 0; i < dataIn.length; i++) {
        var data = dataIn[i]

        var eng = state.engagementScore
        var max = 1
        if (!eng['all']) eng['all'] = []
        eng['all'].push(data)

        if (eng['max']) {
            max = eng["max"]
        }
        var luid = data.luid
        var scoreData = JSON.parse(data.Content)
        if (scoreData && scoreData.engagementScore) {
            var s = scoreData.engagementScore
            var calc = s.calculated;
            if (calc > max) {
                max = calc
            }
            if (eng[luid]) {
                var trendown = false
                let c = eng[luid].current
                if (c > calc) trendown = true
                var per = 100 * calc / max
                var peak = per > eng[luid].peak ? per : eng[luid].peak
                var samp = eng[luid].samples + 1
                let avg = (((samp - 1) * eng[luid].avg) + per) / samp;

                eng[luid] = { current: calc, trenddown: trendown, prev: c, percent: per, peak: peak, samples: samp, avg: avg }
            } else {
                per = 100 * calc / max
                eng[luid] = { current: calc, trenddown: false, prev: 0, samples: 1, peak: per, percent: per, avg: per }
            }
            if (calc >= max) {
                eng['max'] = calc
                var k = Object.keys(eng)
                for (let i = 0; i < k.length; i++) {
                    let kk = k[i]
                    if (kk !== "max" && kk !== "all") {
                        var v = eng[kk]
                        v.percent = (100 * v.current) / max
                        if (v.percent > 100) v.percent = 100
                    }
                }
            }
        }

    }
    return { ...eng }
}

function checkAds(profile, boardType, st) {
    if (st.apiMode && st.apiMode.obj && st.apiMode.obj.content && st.apiMode.obj.content.includes("noAds")) {
        return false
    }
    if (profile === undefined) return true
    if (boardType && boardType.name && boardType.name.length > 0) {
        var isFree = false
        var isStudent = true
        var forTest = false
        if(boardType && boardType.session && boardType.session.TutorClass) return false
        if (!profile || !profile.paidPlan || profile.paidPlan === "educator-free") isFree = true
        if (boardType.name.includes("Instructor")) isStudent = false
        if (boardType.name.includes("Single")) isStudent = false
        // if (profile && profile.email && profile.email.includes("epiphani")) forTest = true
        if (isFree && !isStudent) {
            // check creator of the board too if they have a paid plan
            return true
        }
        if (forTest) {
            return true
        }
    }
    return false
}

function processPoll(d, state) {
    let cp = state.classPoll.messages
    if (!(d.Content.question in cp)) {
        cp[d.Content.question] = {}
    }
    if (d.State !== "DONE") {
        cp[d.Content.question][d.Content.luid] = d
    } else {
        delete cp[d.Content.question][d.Content.luid]
    }
    let open = false;
    Object.values(cp).forEach(q =>(
        Object.values(q).forEach(d => {
            if (d) open = true
        })
    ))
    let ns = {
        ...state,
        classPoll: {
            open: open,
            messages: { ...cp }
        }
    }
    return ns
}

const mainReducer = function (state = initialState, action) {
    switch (action.type) {
        case Actions.SET_SHARED_SCREEN_DIALOG: {
            return {
                ...state,
                sharedScreenDialog: action.data
            }
        }        
        case Actions.SET_EMBEDDED_MODE: {
            return {
                ...state,
                embeddedMode: action.data
            }
        }
        case Actions.ADD_CLASSPOLL_EVENT: {
            if (!action.data) {
                return {
                    ...state,
                    classPoll: {
                        open: false,
                        messages: {}
                    }
                }
            }
            return processPoll(action.data, state)
        }
        case Actions.SET_PAGE_ADDING: {
            return {
                ...state,
                pageAdding: action.data
            }
        }
        case Actions.SET_BOARD_RECORDING: {
            return {
                ...state,
                boardRecording: action.data
            }
        }
        case Actions.SET_SHARED_SCREEN: {
            return {
                ...state,
                sharedScreen: action.data
            }
        }
        case Actions.SET_ALERT_MESSAGES: {
            let ar = []
            if (action.data) {
                ar = [...state.alertMessages.messages, action.data]
            }
            return {
                ...state,
                alertMessages: { messages: ar, open: ar.length > 0 }
            }
        }
        case Actions.PENDING_SUBSCRIPTIONS: {
            let d = action.data
            return {
                ...state,
                pendingSubscriptions: {
                    ...state.pendingSubscriptions,
                    [d.type]: d.value
                }
            }
        }
        case Actions.POP_ALERT_MESSAGES: {
            let ar = state.alertMessages.messages
            ar.shift()
            return {
                ...state,
                alertMessages: { messages: ar, open: ar.length > 0 }
            }
        }
        case Actions.SET_VIDEO_CONFIG: {
            return {
                ...state,
                videoConfig: action.data
            }
        }
        case Actions.SET_MODE: {
            return {
                ...state,
                modeSelected: action.data
            }
        }
        case Actions.SET_MOBILE_MODE: {
            const appMode = mylocalStorage.getItem('appMode');
            return {
                ...state,
                mobileModeSelected: {
                    ...action.data,
                    appMode: appMode
                }
            }
        }
        case Actions.SET_VIDEO_STATE: {
            return {
                ...state,
                videoState: action.data
            }
        }
        case Actions.SET_KEYB_MODE: {
            return {
                ...state,
                keybMode: action.data
            }
        }
        case Actions.SET_ENGAGEMENT_STICKER: {
            return {
                ...state,
                engagementSticker: action.data
            }
        }
        case Actions.SET_API_MODE: {
            return {
                ...state,
                apiMode: action.data
            }
        }
        case Actions.MEETING_TO_JOIN: {
            return {
                ...state,
                meetingToJoin: action.data
            }
        }
        case Actions.UPDATE_ENGAGEMENT_SCORE: {
            return {
                ...state,
                engagementScore: processScore(state, action.data)
            }
        }
        case Actions.FORM_SUBMIT: {
            return {
                ...state,
                formSubmit: formSubmitprocess(state, action.data)
            }
        }
        case Actions.SET_BOARD_TOOLS: {
            let ad = action.data
            if (Object.keys(ad).length === 0 && Object.keys(state.boardTools).length === 0) {
                return state
            }
            return {
                ...state,
                boardTools: action.data
            }
        }
        case Actions.SET_PERSONAL_CONFIG: {
            const pc = action.data
            if (pc.font) {
                mylocalStorage.setItem("font", pc.font)
            }
            if (pc.language) {
                mylocalStorage.setItem("language", pc.language)
                setLangauge()
            }
            if (!pc.inkColor) {
                mylocalStorage.setItem("inkCopy", "yes")
            } else {
                mylocalStorage.removeItem("inkCopy")
            }
            if (pc.dblClickDisable) {
                mylocalStorage.setItem("dblClickDisable", "yes")
            } else {
                mylocalStorage.removeItem("dblClickDisable")
            }
            // window.personalConfig = JSON.stringify(action.data);
            return {
                ...state,
                personalConfig: action.data
            }
        }
        case Actions.SET_BACK_GROUND: {
            var val = action.data ? action.data : {
                color: null,
                pickerOpen: false,
                pickerImageOpen: false,
                notmine: false,
                image: null
            }
            if (val.color === state.background.color &&
                val.pickerImageOpen === state.background.pickerImageOpen &&
                val.pickerOpen === state.background.pickerOpen &&
                val.image === state.background.image &&
                val.notmine === state.background.notmine) {
                return state
            }
            return {
                ...state,
                background: val
            }
        }
        case Actions.SET_TIME_MACHINE: {
            return {
                ...state,
                timeMachine: action.data
            }
        }
        case Actions.SET_MATRIX_GRID: {
            return {
                ...state,
                matrixGrid: action.data
            }
        }
        case Actions.SET_GRID_BROWSER: {
            return {
                ...state,
                gridBrowser: action.data
            }
        }
        case Actions.SET_CLICK_MODE: {
            return {
                ...state,
                clickMode: action.data
            }
        }
        case Actions.SET_DRAWER_WALKER: {
            return {
                ...state,
                drawerWalker: action.data
            }
        }
        case Actions.SET_GRADING_WALKER: {
            return {
                ...state,
                gradingWalker: action.data
            }
        }
        case Actions.SET_SYNC_DISABLED: {
            return {
                ...state,
                syncDisabled: action.data
            }
        }
        case Actions.SET_NOTIFICATION: {
            return {
                ...state,
                hideNotification: action.data
            }
        }
        case Actions.SET_DICE: {
            return {
                ...state,
                diceShow: processDice(state, action.data)
            }
        }
        case Actions.SET_CLOCK_DRIFT: {
            return {
                ...state,
                clockDrift: action.data
            }
        }
        case Actions.SET_PALETTE: {
            var pp = action.data
            if (pp.open || pp.openCol) {
                var rr = {}
                rr.open = pp.open
                rr.openCol = pp.openCol
                mylocalStorage.setItem("palette", JSON.stringify(rr))
            } else {
                mylocalStorage.removeItem("palette")
            }
            return {
                ...state,
                palletDrawer: action.data
            }
        }
        case Actions.TIMER_EVENT: {
            return {
                ...state,
                timer: pendingTimer(state, action.data)
            }
        }
        case Actions.SHOWCASE: {
            return {
                ...state,
                showCase: pendingShowCase(state, action.data)
            }
        }
        case Actions.PAGE_LOCK: {
            return {
                ...state,
                pageLocked: pendingPageLock(state, action.data)
            }
        }
        case Actions.BOARD_LOCK: {
            return {
                ...state,
                boardLocked: pendingLock(state, action.data)
            }
        }
        case Actions.ALL_EVENTS: {
            return {
                ...state,
                allEvents: allEvents(state, action.data)
            }
        }
        case Actions.SET_FOLLOW: {
            return {
                ...state,
                followTeacher: pendingFollow(state, action.data)
            }
        }
        case Actions.SET_RICH_TEXT: {
            return {
                ...state,
                richText: action.data
            }
        }
        case Actions.CHAT_MSG: {
            var ff = pendingChat(state.boardChat, action.data, state)
            return {
                ...state,
                boardChat: {
                    ...state.boardChat,
                    started: ff.started,
                    messages: ff.messages
                }
            }
        }

        case Actions.RAISE_HAND: {
            return {
                ...state,
                assistanceRequest: {
                    studentAsked: studentRaiseHand(action.data),
                    pendingList: []
                }
            }
        }
        case Actions.ASSISTANCE_REQUEST: {
            let ff = pendingRequests(state.assistanceRequest, action.data)
            return {
                ...state,
                assistanceRequest: {
                    studentAsked: ff.studentAsked,
                    pendingList: ff.pendingList
                }
            }
        }
        case Actions.SET_GVPAGE: {
            return {
                ...state,
                gvPage: action.data
            }
        }
        case Actions.SET_COUNTRY: {
            return {
                ...state,
                countryCode: action.data
            }
        }
        case Actions.SET_CLIENT_LOC: {
            return {
                ...state,
                clientLoc: action.data
            }
        }
        case Actions.SET_TEACHER: {
            return {
                ...state,
                teacher: action.data
            }
        }
        case Actions.SET_BOARD_TYPE: {
            return {
                ...state,
                adsEnable: checkAds(state.userProfile, action.data, state),
                boardType: action.data
            }
        }
        case Actions.SET_TAB: {
            return {
                ...state,
                tab: action.data
            }
        }
        case Actions.SET_NOUSER: {
            return {
                ...state,
                noUser: true
            }
        }
        case Actions.SET_USER: {
            setLocal(action.data)
            return {
                ...state,
                user: action.data
            }
        }
        case Actions.SET_EXP_REMIND: {
            return {
                ...state,
                remind: action.data
            }
        }
        case Actions.SET_USER_PROFILE: {
            setLocalPaidPlan(action.data);
            return {
                ...state,
                adsEnable: checkAds(action.data, state.boardType, state),
                userProfile: action.data
            }
        }

        case Actions.SET_TUTOR_GROUP: {
            return {
                ...state,
                tutorGroup: action.data
            }
        }

        case Actions.SET_TUTOR_USERTYPE: {
            return {
                ...state,
                tutorUsertype: action.data
            }
        }
        case Actions.SET_TUTOR_TOURDONE: {
            return {
                ...state,
                tutorTourDone: action.data
            }
        }
        case Actions.SET_SESSION_DIALOG: {
            return {
                ...state,
                sessionDialog: action.data
            }
        }
        case Actions.OPEN_AUTH_DIALOG: {
            return {
                ...state,
                authDialog: action.data
            }
        }
        case Actions.SET_CONFIG: {
            return {
                ...state,
                BoardConfig: { ...action.data }
            }
        }
        case Actions.ADD_ACTIVE: {
            let pp = state.activeUsers
            const data = action.data
            pp[data.id] = data
            return {
                ...state,
                activeUsers: { ...pp }
            }
        }
        case Actions.DELETE_ACTIVE: {
            let pp = state.activeUsers
            const data = action.data
            delete pp[data.id]
            return {
                ...state,
                activeUsers: { ...pp }
            }
        }
        case Actions.ADD_PARTICIPANT: {
            const data = action.data
            if (data.content && data.content.length > 0 && data.content.includes("microsoftteams")) {
                try {
                    let jj = JSON.parse(data.content)
                    if (jj.email) {
                        jj.email = removeMSTeamsEmail(jj.email)
                    }
                    data.email = removeMSTeamsEmail(data.email)
                    data.content = JSON.stringify(jj)
                } catch {

                }
            }
            var idUse = getIdToUse(data)

            if (state.avatarDialogOpen) {
                let ppp = state.pendingParticipants;
                ppp.push({ oper: "ADD", data: data });

                return {
                    ...state,
                    pendingParticipants: [...ppp]
                }
            }

            let pp = state.participants
            pp[idUse] = data
            if (checkIfPartsUpdateNeeded(pp)) {
                gParts = pp;
            } else {
                return state;
            }
            return {
                ...state,
                participants: { ...pp }
            }
        }
        case Actions.REMOVE_PARTICIPANT: {
            const data = action.data
            let idUse = getIdToUse(data)

            if (state.avatarDialogOpen) {
                let ppp = state.pendingParticipants;
                ppp.push({ oper: "REMOVE", data: data });

                return {
                    ...state,
                    pendingParticipants: [...ppp]
                }
            }

            let pp = state.participants
            delete pp[idUse]
            gParts = pp;

            if (idUse in timedOutParticipants) {
                delete timedOutParticipants[idUse];
            }

            return {
                ...state,
                participants: { ...pp }
            }
        }
        case Actions.FLUSH_PARTICIPANT: {
            gParts = {};
            timedOutParticipants = {};

            return {
                ...state,
                pendingParticipants: [],
                participants: {}
            }
        }
        case Actions.AVATAR_DIALOG_OPEN: {
            return {
                ...state,
                avatarDialogOpen: action.data
            }
        }
        case Actions.PROCESS_PENDING_PARTICIPANTS: {
            let ppp = state.pendingParticipants;
            let pp = state.participants;

            for (let idx in ppp) {
                let item = ppp[idx];

                // Defensive check to prevent crash due to data being undefined
                if (!(item && item.data)) continue

                let idUse = getIdToUse(item.data)

                if (item.oper === "ADD") {
                    pp[idUse] = item.data;
                } else {
                    delete pp[idUse];

                    if (idUse in timedOutParticipants) {
                        delete timedOutParticipants[idUse];
                    }

                }
            }

            if (checkIfPartsUpdateNeeded(pp)) {
                gParts = pp;
            }
            return {
                ...state,
                pendingParticipants: [],
                participants: { ...pp }
            }
        }
        case Actions.SET_COMMUNITYUSER: {
            setLocal(action.data)
            return {
                ...state,
                communityUser: action.data
            }
        }
        case Actions.SET_SPINNER_EVENT: {
            return {
                ...state,
                spinnerEvt: processSpinner(state.spinnerEvt, action.data, state.clockDrift)
            }
        }
        case Actions.SET_LETITSNOW_EVENT: {
            return {
                ...state,
                letItSnowEvt: processLetItSnow(state.letItSnowEvt, action.data, state.clockDrift)
            }
        }
        case Actions.SET_FDICE_EVENT: {
            return {
                ...state,
                fdiceEvt: processFdice(state.fDiceEvt, action.data, state.clockDrift)
            }
        }
        default:
            return state;
    }
}

export default mainReducer
